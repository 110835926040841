import axios from "axios";

export let headers = {
	"Tenant-Access-Token": "N2JhYjA4Yjg0NzE5MmVkMGExZjVkYTkyMjEzNTQzYWI=",
	"Tenant-Access-Key": "-txuZIIHgLfCYg",
};

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";

export const api = axios.create({
	baseURL: BASE_URL,
	headers,
});
